import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import TextToSpeech from "../../Common/Reuse/TextToSpeech/TextToSpeech";

const ViewActivities = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const shopee = location.state.shopee;

  return (
    <div className="ViewShoppee outletPadding">
      <Container>
        <Button className="backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />
          Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.BagDash className="me-1" /> Product Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Shopee Image
                  </th>
                  <td>{
                    shopee.imageURL?.map((row) => {
                      return (
                        <img src={row} />
                      )
                    })
                  }
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Product Name
                  </th>
                  <td>{shopee?.name ? shopee?.name : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Market Place URL
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>{shopee?.marketPlaceURL ? shopee?.marketPlaceURL : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Priority
                  </th>
                  <td>{shopee?.priorityNo ? shopee?.priorityNo : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Tag Category
                  </th>
                  <td>{shopee?.tagsCategory ? shopee?.tagsCategory : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Tags
                  </th>
                  <td>{shopee && shopee.tags && shopee.tags.length > 0 ? shopee.tags.join(', ') : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Product Category
                  </th>
                  <td>{shopee?.category ? shopee?.category : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Mood
                  </th>
                  <td className="text-capitalize">{shopee?.moods ? shopee?.moods?.join(", ") : "-"}</td>
                </tr>


                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Page
                  </th>
                  <td>{shopee?.pageName ? shopee?.pageName : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in English
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {shopee?.shortDescriptionLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: shopee?.shortDescriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Marathi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {shopee?.shortDescriptionLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: shopee?.shortDescriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Full Description in English
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {shopee?.descriptionLang?.en ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: shopee?.descriptionLang?.en,
                          }}
                        ></div>
                        <TextToSpeech htmlContent={shopee?.descriptionLang?.en} language="mr" />
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Full Description in Marathi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {shopee?.descriptionLang?.mr ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: shopee?.descriptionLang?.mr,
                          }}
                        ></div>
                        <TextToSpeech htmlContent={shopee?.descriptionLang?.mr} language="mr" />
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewActivities;
