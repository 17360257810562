import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "../../Common/Skeleton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from "../../Auth/LoginSlice";
import { setActiveKey } from "../../Common/ActiveKeySlice";
import PublishModel from "../../Common/Model/PublishModel";
import UnPublishModel from "../../Common/Model/UnPublishModel";
import useDebouncedApiCall from "../../Common/Reuse/Debounce";
import DeleteModel from "../../Common/Model/DeleteModel";
import AddVideoModel from "./Model/AddVideoModel";
import PaginationSequence from "../../Common/Pagination/PaginationSequence";
import EditVideoModel from "./Model/EditVideoModel";
window.Buffer = window.Buffer || require("buffer").Buffer;

const EventList = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData.data);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [eventList, setEventList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [searchField, setSearchField] = useState("");
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishEventId, setPublishEventId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // Edit  video Modal 
  const [showEditEventModal, setShowEditEventModal] = useState(false);
  const [eventdata, setEventData] = useState('')

  // Add Event video Modal 
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [eventid, setEventId] = useState('')

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  // Function to handle opening the publish modal
  const openPublishModal = (event_id) => {
    setPublishEventId(event_id);
    setIsPublishModalOpen(true);
  };


  // Function to handle opening the unpublish modal
  const openUnpublishModal = (event_id) => {
    setUnpublishId(event_id);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };
  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };


  useEffect(() => {
    getEventList()
  }, [docPerPage, pageNo])

  const getEventList = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/priority/titleFilter",
      {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          documentsPerPage: docPerPage,
          page: pageNo,
          type: "Event",
          title: searchField ? searchField : "",

        })
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Authorization failed / Forbidden") {
          setLoader(true);
          dispatch(setLogout(null));
          dispatch(setActiveKey(null));

          localStorage.removeItem('persist:root');
          navigate('/');
        } else if (response.status === 200) {
          setLoader(false);
          setEventList(response);
          setNoOfPages(response?.noOfPages);
        } else {
          setLoader(false)
          setEventList([])
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getEventList, dependencies, setLoader);

  const deleteEvent = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/eventSystem/deleteEventSystem", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        event_id: deleteId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setIsDelete(false);
        getEventList();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // for toggle
  const handlePublishAction = async (event_id, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);

      const result = await fetch(process.env.REACT_APP_BASE_URL + "/eventSystem/publishEventSystem", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          event_id: event_id,
          isPublish: isPublish
        }),
      });
      const response = await result.json();
      if (response) {
        getEventList();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishEventId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };


  const handlerOpenModel = (id) => {
    setEventId(id);
    setShowAddEventModal(true)
  }

  const handlerOpenEditModel = (id) => {
    setEventData(id);
    setShowEditEventModal(true)
  }

  return (
    <div className="outletPadding">
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="Eventlist"
        loader={loader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="Eventlist"
        loader={loader}
      />
      <DeleteModel
        show={isdelete}
        onHide={() => setIsDelete(false)}
        onDelete={deleteEvent}
        loader={loader}
        label="Event"
      />
      <AddVideoModel
        show={showAddEventModal}
        onHide={() => setShowAddEventModal(false)}
        loader={loader}
        setLoader={setLoader}
        getEventList={getEventList}
        eventid={eventid}
        title="Upload Event Intro Video"
      />
      <EditVideoModel
        show={showEditEventModal}
        onHide={() => setShowEditEventModal(false)}
        loader={loader}
        setLoader={setLoader}
        getEventList={getEventList}
        eventdata={eventdata}
      />
      <Container>
        <Row className="justify-content-between">
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>

          <Col
            md={2}
            className="d-flex justify-content-end my-auto mt-3 mt-md-0"
          >
            <Button
              className="primaryBtn"
              onClick={() => navigate("/dashboard/add-event")}
            >
              <Icon.CalendarPlus className="me-2" size={15} />
              Add Event
            </Button>
          </Col>
        </Row>

        {/* Event List */}
        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Title</th>
                <th>Venue</th>
                <th>Event Media</th>
                <th>Date</th>
                <th>Event Category</th>
                <th>City</th>
                <th>Publish</th>
                <th>Intro Video</th>
                <th>Action</th>
              </thead>
              {loader ? (
                <Skeleton rows={10} cols={10} />
              ) : eventList?.data?.length > 0 && eventList?.data !== null && eventList?.data !== undefined ? (
                eventList?.data?.map((itm, index) => {
                  return (
                    <tr key={Math.random() * 999999999}>
                      <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                      <td style={{ width: "230px" }}>{itm?.event_titleLang?.en}</td>
                      <td style={{ width: "200px" }}>{itm?.event_venue}</td>
                      <td>{itm?.event_media}</td>
                      <td style={{ width: "180px" }}>{moment(itm?.event_date).format("DD-MM-YY") + ' To ' + moment(itm?.event_end_date).format("DD-MM-YY")}</td>
                      <td style={{ width: "230px" }}>{itm?.event_category}</td>
                      <td style={{ width: '100px' }}>{itm?.cities?.map((row) => (row)).join(', ')}</td>
                      <td style={{ width: '120px' }}>
                        {itm.isPublish ? (
                          <div
                            style={{
                              backgroundColor: "#8FBC8F",
                              borderRadius: "8px",
                              padding: "4px 8px",
                              color: "white",
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                            onClick={() => openUnpublishModal(itm.event_id)}>
                            Published
                          </div>
                        ) : (
                          <div className="switch"
                            onClick={() => openPublishModal(itm.event_id)}>
                            <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                            <span className="slider round" onClick={() => openPublishModal(itm.event_id)}></span>
                          </div>
                        )}
                      </td>
                      <td>
                        <Icon.PlusCircle size={22} className="icon me-3 text-primary" onClick={() => handlerOpenModel(itm?.event_id)} />
                        <img src={Edit} alt="Edit" className="icon me-3" onClick={() => handlerOpenEditModel(itm)} />
                        {/* <Icon.Eye size={25} /> */}
                      </td>
                      <td>
                        <img src={View} alt="View" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/view-event", {
                              state: {
                                event: itm,
                              },
                            })} />

                        <img src={Edit} alt="Edit" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/edit-event", {
                              state: {
                                event: itm,
                              },
                            })} />

                        <img src={Delete} alt="Delete" className='icon'
                          onClick={() => {
                            setIsDelete(true);
                            setDeleteId(itm?.event_id);
                          }} />
                      </td>

                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>
        </div>

        <PaginationSequence
          data={eventList?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />
      </Container>
    </div>
  );
};

export default EventList;
