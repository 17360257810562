import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pageNo: 1,
    docPerPage: 25,
};

const paginationSlice = createSlice({
    name: 'pagination',
    initialState,
    reducers: {
        setPageNo(state, action) {
            state.pageNo = action.payload;
        },
        setDocPerPage(state, action) {
            state.docPerPage = action.payload;
        },
        resetPagination(state) {
            state.pageNo = 1;
            state.docPerPage = 25;
        },
        setActivePageNo: (state, action) => {
            state.activepageNo = action.payload;
        },
    },
});

export const { setPageNo, setDocPerPage, resetPagination,setActivePageNo } = paginationSlice.actions;
export default paginationSlice.reducer;
