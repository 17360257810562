import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Button, Spinner, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useNavigate } from 'react-router-dom';
import { getAllCategories } from '../../Common/Categories';
import ChromeTab from '../../Common/langugageToggle/ChromeTab';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import { typeList } from '../../../Utils/GlobalConfigs';
import AudioTab from '../../Common/langugageToggle/AudioTab';
import useGetPageList from '../../Common/Reuse/useGetPageList';
import useGetMoodList from '../../Common/Reuse/useGetMoodList';

const AddArticle = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector((state) => state?.cmsLogin?.userData.data);
  const getData = useSelector(state => state?.categories?.categoryList?.data);

  // call custom hook for page title
  const { pageData } = useGetPageList();
  const { moodData } = useGetMoodList();

  // use state for input field
  const [title, setTitle] = useState("");
  const [titleMr, setTitleMr] = useState("")
  const [titleHn, setTitleHn] = useState("")
  const [description, setDescription] = useState("");
  const [descriptionMr, setDescriptionMr] = useState("");
  const [descriptionHn, setDescriptionHn] = useState("");
  const [shortdescription, setShortDescription] = useState("");
  const [shortdescriptionMr, setShortDescriptionMr] = useState("");
  const [shortdescriptionHn, setShortDescriptionHn] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [source, setSource] = useState("");
  const [tagcategories, setTagCategories] = useState("");
  const [type, setType] = useState("");
  const [contentURLs, setContentURLs] = useState([]);
  const [shoppee, setShoppee] = useState([]);
  const [tags, setTags] = useState([]);
  const [categoryTypes, setCategoryTypes] = useState("")
  const [tagCategoryList, setTagCategoriesList] = useState([]);
  const [TagList, setTagList] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [ShopeeList, setShopeeList] = useState([]);
  const [bannerImages, setBannerImages] = useState("");
  const [fileEventBanner, setFileEventBanner] = useState('');
  const [mood, setMood] = useState([]);
  const [audioURL, setAudioURL] = useState("");
  const [audioURLMr, setAudioURLMr] = useState("");
  const [audioURLHn, setAudioURLHn] = useState("");
  const [publishdate, setPublishDate] = useState("")
  const [pageTitle, setpageTitle] = useState('');
  const [author, setAuthor] = useState('');

  const [fileEvent, setFileEvent] = useState('');
  const [img, setImg] = useState('');
  // Error variables
  const [error, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChangeContent = (selected) => {
    setContentURLs(selected);
  };

  const handleChangeShopee = (selected) => {
    setShoppee(selected);
  };


  const OptionContent = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.data.title}</label>
        </components.Option>
      </div>
    );
  };

  const OptionShopee = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };



  // get category data
  const getArticleCategory = getData?.data !== undefined && getData?.data !== null && getData?.data?.length > 0 ?
    getData?.data.map((itm) => {
      return ({ label: itm.tagCategoryName, value: itm.tagCategoryName })
    }) : []

  useEffect(() => {
    getTagCategoryList()
    getContentList()
    getShopeeList()
    getTagList()
    dispatch(getAllCategories({ payload: { categoryType: "feed" }, token: userState.token }))
  }, [])


  useEffect(() => {
    if (tagcategories !== "") {
      getTagList();
    }
  }, [tagcategories]);


  const getTagCategoryList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllTagCat", {
      method: "GET",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
    }).then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          let arr = [];
          response?.data?.map((item) => {
            arr.push({
              ...item,
              label: item.tagCategoryName,
              value: item.tagCategoryName,
            });
          });
          setTagCategoriesList(arr);
        } else {
          setTagCategoriesList([]);
        }
      }).catch((error) => {
        console.log("error", error);
      })
  };

  const getContentList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllContents", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            let arr = [];
            response.data.map((item) => {
              arr.push({
                ...item,
                label: item.title,
                value: item.title,
              });
            });
            setContentList(arr);
          }
        }
      }).catch((error) => {
        console.log("error", error);
      })
  };

  const getShopeeList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllShoppees", {
      method: "GET",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.data.length > 0) {
          let arr = [];
          response.data.map((item) => {
            arr.push({
              ...item,
              label: item.name,
              value: item.name,
            });
          });
          setShopeeList(arr);
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
  };

  const getTagList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTags2',
      {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({ tagCategory: tagcategories.tagCategoryId ? tagcategories.tagCategoryId : tagcategories?.tagCategoryName })

      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response?.status === 200) {
          let arr = [];
          response.data.map((item) => {
            arr.push({
              ...item,
              label: item.tag,
              value: item.tag,
            });
          });
          setTagList(arr);
        } else {
          setTagList([]);
        }
      }).catch((error) => {
        console.log("error", error);
      })
  };

  const emptyState = () => {
    setTitle("");
    setTitleMr("")
    setTitleHn("")
    setDescription("");
    setDescriptionMr("")
    setDescriptionHn("")
    setShortDescription("")
    setShortDescriptionHn("")
    setShortDescriptionMr("")
    setImageURL("");
    setBannerImages("");
    setSource("");
    setTagCategories("");
    setType("");
    setCategoryTypes("")
    setContentURLs([]);
    setShoppee([]);
    setTags([]);
    setMood([]);
    setAuthor("");
  };

  // for validation
  const validate = () => {
    let newErrors = {};

    if (!title) {
      newErrors.title = "*Title is Required";
    }

    if (!titleMr) {
      newErrors.titleMr = "*Title in Marathi is Required";
    }

    // if (!pageTitle) {
    //   newErrors.pageTitle = "*Page Title is Required";
    // }

    if (!img) {
      newErrors.img = "*At least one Image is Required";
    }

    if (!bannerImages) {
      newErrors.bannerImages = "*Select Atleast one Banner Images";
    }

    if (!type) {
      newErrors.type = "*Please Select Article Type";
    }

    if (!tagcategories) {
      newErrors.tagcategories = "*Please Select Tag category";
    }

    if (shoppee.length === 0) {
      newErrors.shoppee = "*At least Select one Shoppe for Article";
    }

    if (!categoryTypes) {
      newErrors.categoryTypes = "*Please Select Category Type";
    }

    if (tags.length === 0) {
      newErrors.tags = "*At least Select one Tag for Article";
    }
    if (mood.length === 0) {
      newErrors.mood = "*Required Field";
    }

    if (!publishdate) {
      newErrors.publishdate = "*Required field"
    }

    // if ((!audioURL) ||
    //   (!audioURLMr)
    //   // ||(!audioURLHn) ||
    // ) {
    //   newErrors.audio = "* Audio in English and Marathi are required";
    // }

    if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
      (!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
      (!shortdescription || shortdescription.trim() === '<p><br></p>') ||
      (!description || description.trim() === '<p><br></p>'))
    //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') ||
    //     (!descriptionHn || descriptionHn.trim() === '<p><br></p>')) 
    {
      newErrors.description = "*Both short and full descriptions in English and Marathi are required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleReactS3UltraRes = (url) => {
    setImg(url);
  }

  const handleReactS3UltraResBanner = (url) => {
    setBannerImages(url)
  }

  // for add article
  const addArticle = async (status) => {
    const isValid = validate();
    if (!isValid) return;
    setLoader(true);
    if (isValid) {
      let payload = {
        title: title,
        titleLang: { en: title, mr: titleMr, hin: titleHn },
        description: descriptionMr,
        descriptionLang: { en: description, mr: descriptionMr, hin: descriptionHn },
        shortDescription: shortdescriptionMr,
        shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
        imageURL: img,
        bigImageURL: bannerImages,
        tagsCategory: tagcategories.label,
        categories: tagcategories.label,
        type: type.label,
        source: source,
        contentId: contentURLs.map((item) => item.contentId),
        contentURL: contentURLs.map((item) => item.label),
        shoppeeId: shoppee.map((item) => item.Id),
        shoppee: shoppee.map((item) => item.label),
        priorityNo: '100',
        moods: mood.map((item) => item?.label),
        moodId: mood.map((item) => item?.value),
        tags: tags.map((item) => item.label),
        tagsId: tags.map((item) => item.Id),
        isHome: true,
        contentCategory: categoryTypes.value,
        createdById: userState.user.name ? userState.user.name : "admin",
        audio: audioURLMr,
        audioLang: { en: audioURL, mr: audioURLMr, hin: audioURLHn },
        toPublishDate: publishdate,
        isPublish: status,
        pageId: pageTitle?.value ? pageTitle?.value : "",
        pageName: pageTitle?.label ? pageTitle?.label : "",
        pageNameLang: pageTitle?.Lang,
        author: author,
      };

      await fetch(process.env.REACT_APP_BASE_URL + "/article/createArticle", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "Article Created Successfully") {
            emptyState();
            toast.success("Article Created Successfully !")
            navigate("/dashboard/articleList")
            setLoader(false)
          } else if (response.message === "Article already exist") {
            toast.error("Article already exists")
            setLoader(false)
          }
        }).catch((err) => {
          console.log("Error", err)
          setLoader(false)
        })
    }
  };


  return (
    <>
      <div className="AddArticle outletPadding">
        <ToastContainer />

        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-1" />Back
          </Button>
        </Row>

        <h3 className='text-center d-flex justify-content-center align-items-center'>
          <Icon.ChatQuote size={20} className='me-2' />Add Article
        </h3>

        <Row>
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Title in English<span>*</span></Form.Label>
              <Form.Control
                placeholder="Enter Title"
                value={title}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[\u0900-\u097F]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    setTitle(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitle(e.target.value);
                  }
                }}
              />
              {error.title && <div className="error">{error.title}</div>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Title in Marathi<span>*</span></Form.Label>
              <Form.Control
                placeholder="शीर्षक प्रविष्ट करा"
                value={titleMr}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleMr(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleMr(e.target.value);
                  }
                }}
              />
              {error.titleMr && <p className="error">{error.titleMr}</p>}
            </Form.Group>
          </Col>

          <Col md={4} className='off'>
            <Form.Group className="mb-3">
              <Form.Label>Title in Hindi</Form.Label>
              <Form.Control
                placeholder="शीर्षक दर्ज करें"
                value={titleHn}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleHn(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleHn(e.target.value);
                  }
                }}
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Add Source</Form.Label>
              <Form.Control
                placeholder="Enter source"
                value={source}
                onChange={(e) => {
                  setSource(e.target.value);
                }}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Image<span>*</span></Form.Label> <br />
              <>
                {!img ?
                  <>
                    <label htmlFor="activityImg" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="activityImg"
                      style={{ display: "none" }}
                      onChange={(event) => setFileEvent(event)} />
                    <p id='imgNote'>Recommanded image resolution - 4/5 (1080*1350 px)</p>
                    {error.img && <div className="error">{error.img}</div>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setImg('')} />
                    </Card>
                  </div>
                }
              </>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" id="img">
              <Form.Label>Banner Image<span>*</span></Form.Label> <br />
              {!bannerImages ?
                <>
                  <label htmlFor="bannerInput" id="uploadBtn">
                    <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    id="bannerInput"
                    style={{ display: "none" }}
                    onChange={(event) => setFileEventBanner(event)} />
                  <p id='imgNote'>Recommanded image resolution - 4/5 (1080*1350 px)</p>
                  {error.bannerImages && <p className="errMsg">{error.bannerImages}</p>}
                </>
                :
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  <Card className="uploadedFile m-1">
                    <img src={bannerImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                    <Icon.XCircleFill className='removeIcon' size={18}
                      onClick={() => setBannerImages('')} />
                  </Card>
                </div>
              }
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Type<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={typeList}
                value={type}
                onChange={(option) => {
                  setType(option);
                }}
              />
              {error.type && <p className="error">{error.type}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Tag category<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={tagCategoryList}
                value={tagcategories}
                onChange={(option) => {
                  setTagCategories(option);
                }}
              />
              {error.tagcategories && (<p className="error">{error.tagcategories}</p>)}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Add Tags Name<span>*</span></Form.Label>
              <Select isDisabled={tagcategories === ""}
                isMulti={true}
                options={TagList}
                isSearchable={true}
                value={tags}
                closeMenuOnSelect={false}
                placeholder={<div style={{ fontSize: '15px' }}>Select Tags</div>}
                onChange={(option) => { setTags(option); }} />
              {error.tags && <p className="error">{error.tags}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Page</Form.Label>
              <Select
                isMulti={false}
                options={pageData}
                value={pageTitle}
                onChange={(option) => {
                  setpageTitle(option);
                }}
              />
              {/* {error.pageTitle && (<p className="error">{error.pageTitle}</p>)} */}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select Content URL</Form.Label>
              <ReactSelect
                options={contentList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option: OptionContent,
                }}
                onChange={handleChangeContent}
                allowSelectAll={true}
                value={contentURLs}
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Select Shopee<span>*</span></Form.Label>
              <ReactSelect
                options={ShopeeList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option: OptionShopee,
                }}
                onChange={handleChangeShopee}
                allowSelectAll={true}
                value={shoppee}
              />
              {error.shoppee && <p className="error">{error.shoppee}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Feed Category<span>*</span></Form.Label>
              <Select
                value={categoryTypes}
                options={getArticleCategory}
                onChange={(option) => setCategoryTypes(option)}
              />
              {error.categoryTypes && <div className="error">{error.categoryTypes}</div>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Mood<span>*</span></Form.Label>
              <Select
                isMulti={true}
                isSearchable={true}
                placeholder="Select mood"
                value={mood}
                onChange={(option) => setMood(option)}
                options={moodData}
              />
              {error.mood && <div className="error">{error.mood}</div>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Author Name</Form.Label>
              <Form.Control
                placeholder="Enter Author name"
                value={author}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[\u0900-\u097F]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    setAuthor(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setAuthor(e.target.value);
                  }
                }}
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Publish Date<span>*</span></Form.Label>
              <Form.Control
                className=""
                type="date"
                name="publish Date"
                value={publishdate}
                min={new Date().toISOString().split("T")[0]}
                onKeyDown={(event) => event.preventDefault()}
                onChange={(e) => setPublishDate(e.target.value)}
              />
              {error.publishdate && <p className="errMsg">{error.publishdate}</p>}
            </Form.Group>
          </Col>
        </Row>

        {/* for audio file */}
        <AudioTab
          audioURLMr={audioURLMr}
          setAudioURLMr={setAudioURLMr}
          audioURL={audioURL}
          setAudioURL={setAudioURL}
          audioURLHn={audioURLHn}
          setAudioURLHn={setAudioURLHn}
        />
        {error.audio && (
          <p className="errMsg mt-1">{error.audio}</p>
        )}


        <Row>
          {/* for description  */}
          <ChromeTab
            shortdescriptionMr={shortdescriptionMr}
            setShortDescriptionMr={setShortDescriptionMr}
            descriptionMr={descriptionMr}
            setDescriptionMr={setDescriptionMr}
            shortdescription={shortdescription}
            setShortDescription={setShortDescription}
            descriptionEn={description}
            setDescriptionEn={setDescription}
            shortdescriptionHn={shortdescriptionHn}
            setShortDescriptionHn={setShortDescriptionHn}
            descriptionHn={descriptionHn}
            setDescriptionHn={setDescriptionHn}
          />
        </Row>
        {error.description && (
          <p className="errMsg mt-1">{error.description}</p>
        )}

        {loader ? (
          <Spinner variant="primary" />
        ) : (
          <>
            <ButtonComponent
              onSave={addArticle}
              onSaveAndPublish={addArticle}
              saveLabel="Save"
              savePublish="Save & Publish"
            />
          </>
        )}
        {
          fileEvent &&
          <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
            directory="articleImages" onFileUpload={handleReactS3UltraRes} type={4 / 5} />
        }

        {
          fileEventBanner &&
          <ReactS3Ultra fileEvent={fileEventBanner} fileType={process.env.REACT_APP_IMAGE}
            directory="articleBanners" onFileUpload={handleReactS3UltraResBanner} type={4 / 5} />
        }
      </div >
    </>
  )
}

export default AddArticle
