import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useSelector } from 'react-redux';
import Pagination from '../../Common/Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';

const CourseLesson = (props) => {

    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const location = useLocation()
    const { courseId, courseTitleLang } = location?.state

    const [loader, setLoader] = useState(false);
    const [searchField, setSearchField] = useState("");
    const [lessondata, setLessonData] = useState([]);
    const [delId, setDelId] = useState('');
    const [showDel, setShowDel] = useState(false);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [publishCourseLessonId, setPublishCourseLessonId] = useState(null);

    // Unpublish video variables
    const [isUnpublish, setIsUnpublish] = useState(false);
    const [unpublishId, setUnpublishId] = useState("");

    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    // Function to handle opening the publish modal
    const openPublishModal = (courseLessonId) => {
        setPublishCourseLessonId(courseLessonId);
        setIsPublishModalOpen(true);
    };

    // Function to handle closing the publish modal
    const closePublishModal = () => {
        setIsPublishModalOpen(false);
    };

    const handlePublishModalCancel = () => {
        setIsPublishModalOpen(false);
    };

    // Function to handle opening the unpublish modal
    const openUnpublishModal = (courseLessonId) => {
        setUnpublishId(courseLessonId);
        setIsUnpublish(true);
    };

    // Function to handle cancelling the unpublish modal
    const closeUnpublishModal = () => {
        setIsUnpublish(false);
    };


    useEffect(() => {
        getAllCourseLesson()
    }, [docPerPage, pageNo])

    // for get all course lesson data
    const getAllCourseLesson = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/getLessonByCourseIdD', {
            method: 'POST',
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
            body: JSON.stringify({
                courseId: courseId,
                title: searchField ? searchField : "",
                documentsPerPage: docPerPage,
                page: pageNo
            })
        })
            .then((res) => res.json())
            .then((response) => {
                if (response) {
                    setLessonData(response);
                    setNoOfPages(response?.noOfPages);
                }
                setLoader(false);
            }).catch((err) => {
                console.log("Err while getting users", err);
                setLoader(false);
            })
    }


    // for custome hook call
    const dependencies = [searchField ? searchField : ""];
    useDebouncedApiCall(getAllCourseLesson, dependencies, setLoader);

    // for deleter courseLesson
    const deleteCourseLesson = async () => {
        setLoader(true);

        try {
            const result = await fetch(process.env.REACT_APP_BASE_URL + '/courseLesson/deleteCourseLesson', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({ courseLessonId: delId })
            });
            const response = await result.json();
            if (response.message === 'Data deleted succussfully') {
                toast.success('CourseLesson Deleted Succussfully');
                setShowDel(false);
                setDelId('');
                setLoader(false);
                getAllCourseLesson();
            }
            else {
                toast.error('Failed to delete, try again');
                setShowDel(false);
                setDelId('');
                setLoader(false);
            }
        } catch (Err) {
            console.log("Err while deleting article", Err);
            setLoader(false);
        }
    }

    // for toggle
    const handlePublishAction = async (courseLessonId, isPublish) => {
        try {
            setIsPublishModalOpen(false);
            setIsUnpublish(false);

            const result = await fetch(process.env.REACT_APP_BASE_URL + "/courseLesson/publishCourseLesson", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    courseLessonId: courseLessonId,
                    isPublish: isPublish
                }),
            });

            const response = await result.json();
            if (response) {
                getAllCourseLesson();
            } else {
                console.log("Error toggling publish status");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to handle confirming publish modal
    const handlePublishModalConfirm = async () => {
        await handlePublishAction(publishCourseLessonId, true);
    };

    // Function to handle confirming unpublish modal
    const handleUnpublishConfirm = async () => {
        await handlePublishAction(unpublishId, false);
    };

    return (
        <div className='Course outletPadding'>
            <PublishModel
                isOpen={isPublishModalOpen}
                onClose={handlePublishModalCancel}
                onConfirm={handlePublishModalConfirm}
                title="Course Lesson"
                loader={loader}
            />
            <UnPublishModel
                isOpen={isUnpublish}
                onClose={closeUnpublishModal}
                onConfirm={handleUnpublishConfirm}
                title="Course Lesson"
                loader={loader}
            />
            <DeleteModel
                show={showDel}
                onHide={() => { setShowDel(false); setDelId(''); }}
                onDelete={deleteCourseLesson}
                loader={loader}
                label="Lesson"
            />
            <ToastContainer />
            <Container>
                <Row className='justify-content-end'>
                    <Col md={12} className='d-flex justify-content-between my-auto mt-3 mt-md-0'>
                        <Button className="backBtn" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />
                            Back
                        </Button>
                    </Col>
                </Row>

                <Row className='justify-content-between mt-2 mb-2'>
                    <Col md={4} className="mb-2 mb-sm-0">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search here"
                            name="search"
                            value={searchField}
                            onChange={(e) => {
                                if (e.target.value.trim()) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                } else if (e.target.value.length === 0) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                }
                            }}
                        ></input>
                    </Col>
                    <Col md={6} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
                        <Button
                            className='primaryBtn'
                            onClick={() => navigate("/dashboard/addlesson", { state: { courseId: courseId, courseTitleLang: courseTitleLang } })}>
                            <Icon.JournalPlus className='me-2' size={16} />Add Lesson
                        </Button>
                    </Col>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Course Lesson
                </h3>

                <h6>Course Title : <span style={{ paddingLeft: '8px' }}>{courseTitleLang}</span></h6>
                <div className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper" style={{ maxHeight: '50vh' }}>
                        <table>
                            <thead>
                                <th>Sr.</th>
                                {/* <th>Course Title</th> */}
                                <th>Lesson Title</th>
                                <th>Priority</th>
                                <th>Publish</th>
                                <th>Action</th>
                            </thead>
                            {loader ? (
                                <Skeleton rows={10} cols={5} key={Math.random() * 999999999} />
                            ) :
                                (
                                    lessondata?.data !== null && lessondata?.data !== undefined && lessondata?.data?.length > 0 ? (
                                        lessondata?.data?.map((itm, index) => {
                                            const { courseLessonId, courseTitleLang, titleLang, srNo } = itm;
                                            return (
                                                <tr key={index}>
                                                    <td>{pageNo !== 1 ? (
                                                        <>
                                                            {' '}
                                                            {index + 1 + docPerPage * (pageNo - 1)}
                                                        </>
                                                    ) : (
                                                        <>{index + 1}</>
                                                    )}</td>

                                                    <td> {titleLang?.en}</td>
                                                    <td>{srNo}</td>
                                                    <td style={{ width: '120px' }}>
                                                        {itm.isPublish ? (
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#8FBC8F",
                                                                    borderRadius: "8px",
                                                                    padding: "4px 8px",
                                                                    color: "white",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => openUnpublishModal(itm.courseLessonId)}
                                                            >
                                                                Published
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="switch"
                                                                onClick={() => openPublishModal(itm.courseLessonId)}
                                                            >
                                                                <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                                                                <span className="slider round" onClick={() => openPublishModal(itm.courseLessonId)}></span>
                                                            </div>
                                                        )}

                                                    </td>

                                                    <td className="d-flex" >
                                                        <img src={View} alt="View" className="icon me-3"
                                                            onClick={() => navigate("/dashboard/coursetopic", { state: { courseId: courseId, courseLessonId: courseLessonId, courseTitleLang: courseTitleLang?.mr, lessonTitleLang: titleLang?.mr } })} />

                                                        <img src={Edit} alt="Edit" className="icon me-3" onClick={() => navigate("/dashboard/editlesson", { state: { rowData: itm, courseId: courseId, courseTitleLang: courseTitleLang?.mr } })}
                                                        />

                                                        <img src={Delete} alt="Delete" className='icon'
                                                            onClick={() => { setShowDel(true); setDelId(courseLessonId); }} />
                                                    </td>

                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <p className='noDataFound'>No Data Found</p>
                                    )
                                )
                            }

                        </table>
                    </div>
                </div>

                <PaginationSequence
                    data={lessondata?.count}
                    pageNo={pageNo}
                    noOfPages={noOfPages}
                    handlePageChange={handlePageChange}
                    handleDocsPerPage={handleDocsPerPage}
                    docPerPage={docPerPage}
                />

            </Container>

        </div>
    )
}


export default CourseLesson;