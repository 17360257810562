import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Card, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../Auth/LoginSlice';
import Skeleton from '../../Common/Skeleton';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../Common/Pagination';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import { toast } from "react-toastify";
import useArticleList from '../../Common/Reuse/useArticleList';
import useContentList from '../../Common/Reuse/useContentList';
import NotificationFilterModel from '../../Common/Model/NotificationFilterModel';
import useEventList from '../../Common/Reuse/useEventList';
import UseActivitiesList from '../../Common/Reuse/UseActivitiesList';
import UseShoppeeList from '../../Common/Reuse/UseShoppeeList';
import UseCourseList from '../../Common/Reuse/UseCourseList';

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const userState = useSelector(state => state?.cmsLogin?.userData?.data);

  // usestate for add data
  const [title, setTitle] = useState('');
  const [Images, setImages] = useState('');
  const [typeName, setTypeName] = useState("");
  const [detailsTitle, setDetailsTitle] = useState('');
  const [description, setDescription] = useState('');

  // usestate for get data
  const [data, setData] = useState('');
  const [loader, setLoader] = useState(false);
  const [notificationloader, setNotificationLoader] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [fileEvent, setFileEvent] = useState('');

  // for check box
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCheck, setSelectedCheck] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Filter modal state
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [pincode, setPincode] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [minage, setMinAge] = useState('');
  const [maxage, setMaxAge] = useState('')

  // for filter model
  const [pincodeOptions, setPincodeOptions] = useState([]);
  // States for Pagination
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(100);
  const [noOfPages, setNoOfPages] = useState(0);

  // state for error
  const [error, setErrors] = useState({});

  // object for type name
  const typeNameOption = [
    { label: "Article", value: "ArticleDetails", icon: 'https://familyguide.s3.ap-south-1.amazonaws.com/Survey/ArticleIcon.png' },
    { label: "Activity", value: "Home", icon: 'https://familyguide.s3.ap-south-1.amazonaws.com/Survey/ActivityIcon.png' },
    { label: "Event", value: "EventDetail", icon: 'https://familyguide.s3.ap-south-1.amazonaws.com/Survey/EventIcon.png' },
    { label: "Shoppee", value: "ProductDetail", icon: '' },
    { label: "Course", value: "CourseDetails", icon: 'https://familyguide.s3.ap-south-1.amazonaws.com/Survey/CourseIcon.png' },
    { label: "Content", value: "ContentDetails", icon: 'https://familyguide.s3.ap-south-1.amazonaws.com/Survey/VideoIcon.png' },
    { label: "Other", value: "Notification", icon: '' },
  ]

  // Function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };
  // Function to handle opening and closing filter modal
  const handleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  // Function to handle submitting filter
  const handleSubmitFilter = () => {
    setPageNo(1);
    getAllUsers();
    setShowFilterModal(false);
  };

  // Function to handle resetting filter
  const handleResetFilter = () => {
    setPincode('');
    setAge(null);
    setGender(null)
    setMinAge('');
    setMaxAge('');
    setPincodeOptions([]);
    setPageNo(1);
    getAllUsers();
  };

  const clearFormFields = () => {
    setTitle('')
    setDescription('')
    setImages('')
    setTypeName('')
    setDetailsTitle('')
    setSelectedRows([]);
    setSelectedCheck([]);
    setSelectAll(false);
    setSearchField('')
  };


  const handleReactS3UltraRes = (url) => {
    setImages(url);
  }

  // for validation
  const validate = () => {
    let newErrors = {};

    if (!title) {
      newErrors.title = "*Required field";
    }
    if (!description) {
      newErrors.description = "*Required field";
    }
    if (!typeName) {
      newErrors.typeName = "*Required field"
    }
    // if (!detailsTitle) {
    //   newErrors.detailsTitle = "*Required field"
    // }
    if (!detailsTitle && typeName?.value !== "Notification") {
      newErrors.detailsTitle = "*Required field"
    }
    if (selectedCheck.length === 0) {
      newErrors.selectedCheck = "*Please select at least one check box"
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // call custom hook
  const { articleList, getArticleList } = useArticleList(userState.token, setLoader)
  const { contentList, getVideoList } = useContentList(userState.token, setLoader)
  const { eventList, getEventList } = useEventList(userState.token, setLoader)
  const { activityList, getAllActivities } = UseActivitiesList(userState.token, setLoader)
  const { shoppeeList, getAllShoppee } = UseShoppeeList(userState.token, setLoader)
  const { courseList, getAllCourse } = UseCourseList(userState.token, setLoader)

  useEffect(() => {
    if (typeName?.value === 'ArticleDetails') {
      getArticleList()
    } else if (typeName?.value === 'EventDetail') {
      getEventList()
    } else if (typeName?.value === 'Home') {
      getAllActivities()
    } else if (typeName?.value === 'ProductDetail') {
      getAllShoppee()
    } else if (typeName?.value === 'CourseDetails') {
      getAllCourse()
    } else if (typeName?.value === 'ContentDetails') {
      getVideoList()
    }
  }, [typeName])


  // useeffect for get all user
  useEffect(() => {
    getAllUsers()
  }, [pageNo, docPerPage])

  // for get alluser
  const getAllUsers = async () => {

    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + '/cms/getNotificationUser', {
      method: 'POST',
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState?.token}` },
      body: JSON.stringify({
        pincode: pincode ? pincode?.value : "",
        searchTitle: searchField ? searchField : "",
        minAge: minage,
        maxAge: maxage,
        gender: gender?.value,
        documentsPerPage: docPerPage,
        page: pageNo
      })
    }).then((res) => res.json()).then((response) => {
      if (response.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));
        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(response?.data);
        setNoOfPages(response?.data?.noOfPages);
        setLoader(false)
      }

    }).catch((err) => {
      console.log("Err while getting users", err);
      setLoader(false);
    })
  }

  // for custome hook call
  const dependencies = [searchField];
  useDebouncedApiCall(getAllUsers, dependencies, setLoader);

  //  for pincode dropdown
  useEffect(() => {
    if (data?.data) {
      console.log("data123", data?.data);

      const uniquePincodes = [...new Set(data?.data.map(item => item.pincode))];
      const filteredPincodes = uniquePincodes.filter(pin => pin !== undefined);
      setPincodeOptions(filteredPincodes.map(pin => ({ value: pin, label: pin })));
    }
  }, [data]);

  // Function to handle individual row selection
  const handleRowSelect = (item, index) => {
    if (selectedRows.filter(row => row.fireBaseToken === item.fireBaseToken).length > 0) {
      let arr = selectedRows
      let getvalue = arr.findIndex((row => row.fireBaseToken === item.fireBaseToken))
      arr.splice(getvalue, 1);

      let checkArray = [...selectedCheck]
      checkArray[index] = false
      setSelectedCheck(checkArray)
      setSelectedRows(arr)
    } else {
      let arr = selectedRows
      arr.push(item)
      setSelectedRows(arr)
      let checkArray = [...selectedCheck]
      checkArray[index] = true
      setSelectedCheck(checkArray)
    }
  };

  // Function to handle "Select All" checkbox
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allRowTokens = data?.data.map((item) => item.fireBaseToken);
      setSelectedRows(allRowTokens);
      const allCheckArray = new Array(data?.data.length).fill(true);
      setSelectedCheck(allCheckArray);
    } else {
      setSelectedRows([]);
      const allCheckArray = new Array(data?.data.length).fill(false);
      setSelectedCheck(allCheckArray);
    }
  };

  // for key
  const keyMap = {
    "ArticleDetails": "articleId",
    "EventDetail": "eventId",
    "CourseDetails": "courseId",
    "ContentDetails": "contentId",
    "Home": "",
    "ProductDetail": ""
  };


  // for Send notification
  const sendNotification = async () => {
    const isValid = validate();
    setNotificationLoader(true);
    if (isValid) {
      const firebasetoken = selectAll ? data?.data.map((item) => item.fireBaseToken) : selectedRows.map((itm) => itm.fireBaseToken)
      // for type wise get title id
      let selectedId = '';
      const listMap = {
        'ArticleDetails': detailsTitle?.value,
        'Home': activityList.find(itm => itm.title === detailsTitle?.label)?.Id,
        'EventDetail': eventList.find(itm => itm.title === detailsTitle?.label)?.Id,
        'ProductDetail': shoppeeList.find(itm => itm.title === detailsTitle?.label)?.Id,
        'CourseDetails': courseList.find(itm => itm.title === detailsTitle?.label)?.Id,
        'ContentDetails': contentList.find(itm => itm.title === detailsTitle?.label)?.Id,
      };
      selectedId = listMap[typeName?.value];
      let payload = {
        firebaseToken: firebasetoken,
        notificationTitle: title,
        notificationBody: description,
        notificationImage: Images,
        notificationData: {
          screenName: typeName?.value === 'NotificationNo' ? 'NotificationNo' : typeName?.value,
          id: selectedId ? selectedId : '',
          key: keyMap[typeName?.value] || '',
          iconURL: typeName?.icon,
          // isID: selectedId ? "true" : "false",
        }
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/notification/sendNotification`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${userState?.token}`
          },
          body: JSON.stringify(payload),
        });

        if (response) {
          const responseData = await response.json();
          toast.success("send notification successfully")
          clearFormFields()
          setNotificationLoader(false)
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.error("Error while creating content:", error);
        setNotificationLoader(false);
      }
    }
  }


  return (
    <div className="outletPadding">
      <NotificationFilterModel
        show={showFilterModal}
        handleClose={handleFilterModal}
        handleFilterSubmit={handleSubmitFilter}
        handleFilterReset={handleResetFilter}
        pincodeOptions={pincodeOptions}
        pincode={pincode}
        setPincode={setPincode}
        age={age}
        setAge={setAge}
        gender={gender}
        setGender={setGender}
        minage={minage}
        maxage={maxage}
        setMinAge={setMinAge}
        setMaxAge={setMaxAge}
      />
      <Container>
        <Row>
          {/* for get user */}
          <Col md={6} >
            <Row>
              <Col md={8} className="d-flex">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by name and mobile"
                  name="search"
                  value={searchField}
                  onChange={(e) => {
                    if (e.target.value.trim()) {
                      setSearchField(e.target.value)
                      setPageNo(1);
                    } else if (e.target.value.length === 0) {
                      setSearchField(e.target.value)
                      setPageNo(1);
                    }
                  }}
                />
                <Button
                  className="primaryBtn me-4 ms-2"
                  onClick={handleFilterModal}
                >
                  <Icon.Filter className="me-1" />
                  Filter
                </Button>
              </Col>
              <Col md={12}>
                {error.selectedCheck && <div className="error mt-3">{error.selectedCheck}</div>}
                <div className="outer-wrapper mx-auto mt-4">
                  <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
                    <table style={{ width: '100%  ' }}>
                      <thead>
                        <th style={{ width: '75px' }}>
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          /> All</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Pincode</th>
                        <th>Birth Date</th>
                      </thead>
                      {
                        loader ? <Skeleton rows={8} cols={6} /> :
                          data?.data !== null && data?.data !== undefined && data?.data?.length > 0 ? data?.data?.map((item, index) => {
                            const { fullName, mobile, pincode, dateOfBirth } = item

                            return (
                              <tr key={Math.random() * 999999999}>
                                <td className='d-flex align-items-center'>
                                  <input
                                    type="checkbox"
                                    checked={selectedCheck[index]}
                                    onChange={() => handleRowSelect(item, index)}
                                  />
                                  <span className='ms-2'>{pageNo !== 1 ? (
                                    <>{index + 1 + docPerPage * (pageNo - 1)} </>
                                  ) : (<>{index + 1}</>)}</span>
                                </td>
                                <td style={{ width: '100px' }}>{fullName ? fullName : "-"}</td>
                                <td>{mobile ? mobile : "-"}</td>
                                <td>{pincode ? pincode : "-"}</td>
                                <td>{dateOfBirth ? new Date(dateOfBirth).toLocaleDateString("en-GB") : "-"}</td>
                              </tr>
                            )
                          })
                            : <p className='noDataFound'>No Data Found</p>
                      }
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={12} className='d-flex justify-content-end'>
                <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
                  onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
              </Col>
            </Row>
          </Col>
          {/* for add notification form */}
          <Col md={6} >
            <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
              <Icon.JournalPlus size={20} className='me-2' />Push Notification

            </h3>
            <Row className='mt-4 mx-4'>

              <Col md={12}>
                <Form.Group className='mb-2'>
                  <Form.Label>Title<span>*</span></Form.Label>
                  <Form.Control placeholder="Enter Notification Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                  {error.title && <div className="error">{error.title}</div>}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Images</Form.Label> <br />
                  {!Images ?
                    <>
                      <label htmlFor="fileInput" id="uploadBtn">
                        <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                      </label>
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/gif"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={(event) => setFileEvent(event)} />
                      <p id='imgNote'>Recommended image resolution - 1:1</p>
                    </>
                    :
                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                      <Card className="uploadedFile m-1">
                        <img src={Images} style={{ width: 60, height: 60 }} className='mx-auto' />
                        <Icon.XCircleFill className='removeIcon' size={18}
                          onClick={() => setImages('')} />
                      </Card>
                    </div>
                  }
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label>Type<span>*</span></Form.Label>
                  <Select
                    value={typeName}
                    onChange={(selectedOption) => { setTypeName(selectedOption); setDetailsTitle('') }}
                    options={typeNameOption.map((type) => ({ value: type.value, label: type.label, icon: type.icon }))}
                    placeholder="Select Type"
                    isSearchable
                  />
                  {error.typeName && <div className="error">{error.typeName}</div>}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label>Details{typeName?.value !== 'Notification' && <span>*</span>}</Form.Label>
                  <Select
                    value={detailsTitle}
                    onChange={(selectedOption) => setDetailsTitle(selectedOption)}
                    options={typeName?.value === 'ArticleDetails' ?
                      articleList.map((article) => ({ value: article.Id, label: article.title })) :
                      typeName?.value === 'Home' ?
                        activityList.map((activity) => ({ value: activity.Id, label: activity.title })) :
                        typeName?.value === 'EventDetail' ?
                          eventList.map((event) => ({ value: event.Id, label: event.title })) :
                          typeName?.value === 'ProductDetail' ?
                            shoppeeList.map((shoppee) => ({ value: shoppee.Id, label: shoppee.title })) :
                            typeName?.value === 'CourseDetails' ?
                              courseList.map((course) => ({ value: course.Id, label: course.title })) :
                              typeName?.value === 'ContentDetails' ?
                                contentList.map((content) => ({ value: content.Id, label: content.title })) :
                                typeName?.value === 'Notification' ?
                                  [{ value: 'Other', label: 'Other' }] :
                                  []
                    }
                    placeholder="Select Title"
                    isSearchable
                    isDisabled={!typeName}
                  />
                  {typeName?.value !== 'Notification' && error.detailsTitle && <div className="error">{error.detailsTitle}</div>}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label>Description<span>*</span></Form.Label>
                  <textarea className='form-control' rows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {error.description && <div className='error'>{error.description}</div>}
                </Form.Group>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
              <Button className="primaryBtn me-4" onClick={sendNotification}>
                <Icon.Save className="me-1" />
                Send Notification
              </Button>

            </div>
          </Col>
        </Row>
      </Container >

      {
        fileEvent &&
        <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
          directory="Images" onFileUpload={handleReactS3UltraRes} />
      }

    </div >
  )
}

export default Notification