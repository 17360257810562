import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const ViewActivities = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const activities = location.state.activities;

  return (
    <div className="outletPadding">
      <Container>
        <Button className=" backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" /> Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Clipboard2Data className="me-1" /> Activity Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in English
                  </th>
                  <td>{activities?.titleLang.en ? activities?.titleLang.en : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in Marathi
                  </th>
                  <td>{activities?.titleLang.mr ? activities?.titleLang.mr : "-"}</td>
                </tr>

                <tr className="off">
                  <th>
                    <Icon.Code className="me-2" />
                    Title in Hindi
                  </th>
                  <td>{activities?.titleLang.hin ? activities?.titleLang.hin : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Point
                  </th>
                  <td>{activities?.point ? activities?.point : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Duration
                  </th>
                  <td>{activities?.timeInMin ? activities?.timeInMin : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Time(Min)
                  </th>
                  <td>{activities?.timeInMin ? activities?.timeInMin : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Type
                  </th>
                  <td>{activities?.type ? activities?.type : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Priority
                  </th>
                  <td>{activities?.priorityNo ? activities?.priorityNo : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Tag Category
                  </th>
                  <td>{activities?.tagsCategory?.[0] ? activities?.tagsCategory?.[0] : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Tags
                  </th>
                  <td>
                    {activities?.tags ? activities?.tags?.map((row) => (row)).join(', ') : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Page
                  </th>
                  <td>{activities?.pageName ? activities?.pageName : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Moods
                  </th>
                  <td className="text-capitalize">
                    {activities?.moods ? activities?.moods?.join(", ") : "-"}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in English
                  </th>
                  <td>
                    {activities?.shortDescriptionLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: activities?.shortDescriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Marathi
                  </th>
                  <td>
                    {activities?.shortDescriptionLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: activities?.shortDescriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewActivities;
