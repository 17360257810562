import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../Common/Pagination';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { useNavigate } from 'react-router-dom';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';
import moment from 'moment';


const Course = () => {
  const navigate = useNavigate()
  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [delId, setDelId] = useState('');
  const [showDel, setShowDel] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishCourseId, setPublishCourseId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }
  // Function to handle opening the publish modal
  const openPublishModal = (courseId) => {
    setPublishCourseId(courseId);
    setIsPublishModalOpen(true);
  };

  // Function to handle closing the publish modal
  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (courseId) => {
    setUnpublishId(courseId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };

  useEffect(() => {
    getAllCourse()
  }, [docPerPage, pageNo])

  // ------- Get All Course ----------------------
  const getAllCourse = async () => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({
          type: "Course",
          title: searchField ? searchField : "",
          documentsPerPage: docPerPage,
          page: pageNo
        })
      })
      const responseData = await response.json();
      if (responseData.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(responseData);
        setNoOfPages(responseData?.noOfPages);
        setLoader(false);
      }
    } catch (Err) {
      console.log("Err while getting activites", Err);
      setLoader(false);
    }
  }

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllCourse, dependencies, setLoader);

  // for delete course
  const deleteCourse = async () => {
    setLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/course/deleteCourse', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({ courseId: delId })
      });
      const response = await result.json();
      if (response.message === 'Data deleted succussfully') {
        toast.success('Course Deleted Succussfully');
        setShowDel(false);
        setDelId('');
        setLoader(false);
        getAllCourse();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting article", Err);
      setLoader(false);
    }
  }

  // for toggle
  const handlePublishAction = async (courseId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);

      const result = await fetch(process.env.REACT_APP_BASE_URL + "/course/publishCourse", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({
          courseId: courseId,
          isPublish: isPublish
        }),
      });

      const response = await result.json();
      if (response) {
        getAllCourse();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishCourseId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };


  return (
    <div className='Course outletPadding'>
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="Course"
        loader={loader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="Course"
        loader={loader}
      />
      <DeleteModel
        show={showDel}
        onHide={() => { setShowDel(false); setDelId(''); }}
        onDelete={deleteCourse}
        loader={loader}
        label="Course"
      />
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>

          <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => navigate("/dashboard/Addcourse")}>
              <Icon.JournalPlus className='me-2' size={16} />Add Course
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Image</th>
                <th>Title</th>
                <th>Author</th>
                <th>Lessons</th>
                <th>Duration</th>
                <th>Publish</th>
                <th>Publish Date</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={10} cols={10} key={Math.random() * 999999999} /> :
                  data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {
                    const { imageURL, categories, author, noOfLesson, duration, courseId, titleLang, titleHeaderLang } = itm

                    return (

                      <tr key={index}>
                        <td>
                          {pageNo !== 1 ? (
                            <>
                              {' '}
                              {index + 1 + docPerPage * (pageNo - 1)}
                            </>
                          ) : (
                            <>{index + 1}</>
                          )}
                        </td>

                        <td className='d-flex flex-column'>
                          <img src={imageURL} alt="Course Img" className='mb-3' width={40} />
                        </td>
                        <td style={{ width: '230px' }}>{titleLang?.en}</td>
                        <td style={{ width: '150px' }}>{author}</td>
                        <td>{noOfLesson}</td>
                        <td>{duration}</td>
                        <td style={{ width: '120px' }}>
                          {itm.isPublish ? (
                            <div
                              style={{
                                backgroundColor: "#8FBC8F",
                                borderRadius: "8px",
                                padding: "4px 8px",
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer"
                              }}
                              onClick={() => openUnpublishModal(itm.courseId)}
                            >
                              Published
                            </div>
                          ) : (
                            <div
                              className="switch"
                              onClick={() => openPublishModal(itm.courseId)}
                            >
                              <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                              <span className="slider round" onClick={() => openPublishModal(itm.courseId)}></span>
                            </div>
                          )}

                        </td>
                        <td>{itm?.toPublishDate ? moment(itm?.toPublishDate).format("DD-MM-YY") : moment(itm?.updatedAt).format("DD-MM-YY")}</td>

                        <td className="d-flex">
                          <img src={View} alt="View" className="icon me-3"
                            onClick={() => navigate("/dashboard/courselesson", { state: { courseId: courseId, courseTitleLang: titleLang?.mr } })} />

                          <img src={Edit} alt="Edit" className="icon me-3" onClick={() => navigate("/dashboard/editcourse", { state: { item: itm } })}
                          />

                          <img src={Delete} alt="Delete" className='icon'
                            onClick={() => { setShowDel(true); setDelId(courseId); }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <PaginationSequence
          data={data?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />
      </Container>

    </div>
  )
}

export default Course