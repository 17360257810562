import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "../../Common/Skeleton";
import { useNavigate } from "react-router-dom";
import videoLogo from "../../../Assets/video-icon.svg";
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import NoImage from '../../../Assets/NoImage.svg';
import { setLogout } from "../../Auth/LoginSlice";
import { setActiveKey } from "../../Common/ActiveKeySlice";
import PublishModel from "../../Common/Model/PublishModel";
import UnPublishModel from "../../Common/Model/UnPublishModel";
import useDebouncedApiCall from "../../Common/Reuse/Debounce";
import DeleteModel from "../../Common/Model/DeleteModel";
import PaginationSequence from "../../Common/Pagination/PaginationSequence";
import moment from "moment";
import { toast } from "react-toastify";

const AgnihotraList = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const token = useSelector((state) => state?.cmsLogin?.userData?.data?.token);

  // Filter variables
  const [searchField, setSearchField] = useState("");

  // States for Pagination
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState(0);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishagnihotraId, setPublishAgnihotraId] = useState(null);
  const [loader, setLoader] = useState(false);

  // Delete Article variables
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // State for content list and filtered content list
  const [agnihotraList, setagnihotraList] = useState([]);

  // Function to handle opening the publish modal
  const openPublishModal = (agnihotraStepId) => {
    setPublishAgnihotraId(agnihotraStepId);
    setIsPublishModalOpen(true);
  };


  // Function to handle cancelling the publish modal
  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (agnihotraStepId) => {
    setUnpublishId(agnihotraStepId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };

  // Function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getAgnihotraData()
  }, [pageNo, docPerPage])

  // Get all agnihotra list
  const getAgnihotraData = async () => {
    try {
      setLoader(true);
      const result = await fetch(process.env.REACT_APP_BASE_URL + "/priority/titleFilter", {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
        body: JSON.stringify({
          type: "Agnihotra",
          title: searchField,
          documentsPerPage: docPerPage,
          page: pageNo
        })
      });

      const response = await result.json();
      if (response.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else if (response.status === 200) {
        setagnihotraList(response);
        setNoOfPages(response?.noOfPages);
        setLoader(false);
      } else {
        setagnihotraList([]);
        setLoader(false);
      }
    } catch (err) {
      console.log("Error while getting video list", err);
      setLoader(false);
    }
  };

  // for debounding
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAgnihotraData, dependencies, setLoader);

  // for delete api
  const deleteAgnihotraData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + "/cms/deleteAgnihotraStep", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({
          agnihotraStepId: deleteId,
        }),
      });
      const result = response.json()
      if (result.message === "Data deleted succussfully") {
        toast.success("Deleted Successfully !")
        setIsDelete(false);
        getAgnihotraData();
      } else {
        getAgnihotraData();
        setIsDelete(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish or unpublish modal
  const handlePublishAction = async (agnihotraStepId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);
      const result = await fetch(process.env.REACT_APP_BASE_URL + "/cms/publicAgnihotraStep", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({
          agnihotraStepId: agnihotraStepId,
          isPublish: isPublish
        }),
      });

      const response = await result.json();
      if (response) {
        getAgnihotraData();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async (status) => {
    await handlePublishAction(publishagnihotraId, status);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async (status) => {
    await handlePublishAction(unpublishId, status);
  };

  return (
    <div className="outletPadding">
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="Agnihotra"
        loader={loader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="Agnihotra"
        loader={loader}
      />

      <DeleteModel
        show={isDelete}
        onHide={() => setIsDelete(false)}
        onDelete={deleteAgnihotraData}
        loader={loader}
        label="Agnihotra"
      />
      {/* Add Content and Filter menu */}
      <Row className="justify-content-between mt-3 mt-md-0">
        <Col md={4} className="mb-2 mb-sm-0 d-flex justify-content-start align-items-center">
          <input
            className="form-control"
            type="text"
            placeholder="Search here"
            name="search"
            value={searchField}

            onChange={(e) => {
              if (e.target.value.trim()) {
                setSearchField(e.target.value)
                setPageNo(1);
              } else if (e.target.value.length === 0) {
                setSearchField(e.target.value)
                setPageNo(1);
              }
            }}
          />
        </Col>
        <Col md={6} className="d-flex justify-content-end">
          <Button className="primaryBtn" onClick={() => navigate("/dashboard/add-agnihotra")}>
            <Icon.PlusCircle className="me-2" size={15} />
            Add Agnihotra
          </Button>
        </Col>
      </Row>

      {/* Display agnihotra list */}
      <div className="outer-wrapper mx-auto mt-4">
        <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
          <table>
            {/* Table header */}
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>URL</th>
                <th>Title</th>
                <th>Date</th>
                <th>Type</th>
                <th>Publish</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* Table body */}
              {loader ? (
                <Skeleton rows={10} cols={9} />
              ) : agnihotraList?.data?.length > 0 ? (
                agnihotraList?.data?.map((itm, index) => {
                  return (
                    <tr key={Math.random() * 999999999} >
                      <td>{pageNo !== 1 ? (
                        <>
                          {' '}
                          {index + 1 + docPerPage * (pageNo - 1)}
                        </>
                      ) : (
                        <>{index + 1}</>
                      )}</td>
                      <td>
                        <div class="image-container">
                          <a target="_blank" href={itm?.imageURL}>
                            <img src={itm?.imageURL} style={{ width: 60, height: 60 }} alt="Image" />
                          </a>
                          {itm?.videoURL ? (
                            <a target="_blank" href={itm?.videoURL} class="video-icon">
                              <img src={videoLogo} alt="Video" style={{ width: 30, height: 30 }} />
                            </a>
                          ) : (
                            <img src={NoImage} alt="No Video" class="no-video-icon" />
                          )}
                        </div>
                      </td>
                      <td style={{ width: "18vw" }}>{itm?.title}</td>
                      <td style={{ width: '250px' }}>{moment(itm?.startTime).format("DD-MM-YY") + ' To ' + moment(itm?.endTime).format("DD-MM-YY")}</td>
                      <td >{itm?.type}</td>
                      <td>
                        {itm.isPublish ? (
                          <div
                            style={{
                              backgroundColor: "#8FBC8F",
                              borderRadius: "8px",
                              padding: "4px 8px",
                              color: "white",
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                            onClick={() => openUnpublishModal(itm.agnihotraStepId)}
                          >
                            Published
                          </div>
                        ) : (
                          <div
                            className="switch"
                            onClick={() => openPublishModal(itm.agnihotraStepId)}
                          >
                            <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                            <span className="slider round" onClick={() => openPublishModal(itm.agnihotraStepId)}></span>
                          </div>
                        )}

                      </td>
                      <td className="d-flex">
                        <img src={View} alt="View" className="icon me-3"
                          onClick={() => navigate("/dashboard/view-agnihotra", {
                            state: { agnihotraData: itm }
                          })
                          } />

                        <img src={Edit} alt="Edit" className="icon me-3"
                          onClick={() => navigate("/dashboard/edit-agnihotra", {
                            state: itm
                          })
                          }
                        />

                        <img src={Delete} alt="Delete" className='icon'
                          onClick={() => {
                            setIsDelete(true);
                            setDeleteId(itm?.agnihotraStepId);
                          }} />
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">No Data Found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <PaginationSequence
        data={agnihotraList?.count}
        pageNo={pageNo}
        noOfPages={noOfPages}
        handlePageChange={handlePageChange}
        handleDocsPerPage={handleDocsPerPage}
        docPerPage={docPerPage}
      />
    </div >
  );
};

export default AgnihotraList;
