import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '../../Common/Skeleton';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import moment from 'moment';
import Edit from '../../../Assets/Edit.svg';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';

const Panchang = () => {

    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();
    const [StartDate, setStartDate] = useState("")
    const [EndDate, setEndDate] = useState("")
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");

    // for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    //call function
    useEffect(() => {
        getAllPanchang()
    }, [docPerPage, pageNo])



    const getAllPanchang = async (StartDate, EndDate) => {
        setLoader(true)
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllPanchPGN', {
                method: 'POST',
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    fromDate: StartDate,
                    toDate: EndDate,
                    documentsPerPage: docPerPage,
                    page: pageNo,
                }),

            })
            const responseData = await response.json();
            if (responseData.message === "Authorization failed / Forbidden") {
                setLoader(true);
                dispatch(setLogout(null));
                dispatch(setActiveKey(null));
                localStorage.removeItem('persist:root');
                navigate('/');
            } else {
                setNoOfPages(responseData?.noOfPages);
                setData(responseData);
                setLoader(false);
            }

        } catch (Err) {
            console.log("Err while getting activites", Err);
            setLoader(false);

        }
    }

    // for handle search 
    const handleSearch = () => {
        let isValid = true;
        if (!StartDate) {
            setStartDateError("Please select a start date");
            isValid = false;
        }
        else if (!EndDate) {
            setEndDateError("Please select an end date");
            isValid = false;
        }
        else if (isValid) {
            getAllPanchang(StartDate, EndDate);
            setStartDateError("")
            setEndDateError("")
        }
    };

    // for handle reset
    const handleReset = () => {
        setStartDate("");
        setEndDate("");
        setStartDateError("");
        setEndDateError("");
        getAllPanchang("", "");
    };
    return (
        <div className='Transactions outletPadding'>
            <Container>

                <Row className='d-flex justify-content-end'>
                    <Col md={12} className='d-flex justify-content-end align-items-center'>
                        <div className='d-flex flex-row'>
                            <div className="d-flex flex-column me-2">
                                <input
                                    type="date"
                                    className="form-control me-2"
                                    placeholder="Search here"
                                    name="fromDate"
                                    value={StartDate ? new Date(StartDate).toISOString().split('T')[0] : ''}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    max={EndDate}
                                    required
                                />
                                {startDateError && (
                                    <p className="errMsg">{startDateError}</p>
                                )}
                            </div>
                            <div className="d-flex flex-column me-2">
                                <input
                                    type="date"
                                    className="form-control me-2"
                                    placeholder="Search here"
                                    name="toDate"
                                    value={EndDate ? new Date(EndDate).toISOString().split('T')[0] : ''}
                                    min={StartDate}
                                    disabled={!StartDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    required
                                />
                                {endDateError && (
                                    <p className="errMsg">{endDateError}</p>
                                )}
                            </div>
                            <Button className='primaryBtn' onClick={handleSearch}>
                                <Icon.Search size={20} />
                            </Button>
                            <Button className='primaryBtn ms-2' onClick={handleReset}>
                                <Icon.ArrowClockwise size={20} />
                            </Button>

                        </div>
                    </Col>
                </Row>

                <div className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper" style={{ height: '68vh' }}>
                        <table>
                            <thead>
                                <th>Sr.</th>
                                <th>Travel Direction</th>
                                <th>Energy</th>
                                <th>MoonPhase</th>
                                <th>MoonSigns</th>
                                <th>MarathiMonth</th>
                                <th>EnglishMonth</th>
                                <th>Nakshtra</th>
                                <th>Day</th>
                                <th>Date</th>
                                <th>Action</th>
                            </thead>
                            {
                                loader ? <Skeleton rows={10} cols={11} /> :
                                    data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {

                                        const { travelDirection, energy, moonPhase, moonSigns, marathiMonth, englishMonth, nakshtra, day, date } = itm
                                        return (

                                            <tr key={Math.random() * 999999999}>
                                                <td>{pageNo !== 1 ? (
                                                    <>
                                                        {' '}
                                                        {index + 1 + docPerPage * (pageNo - 1)}
                                                    </>
                                                ) : (
                                                    <>{index + 1}</>
                                                )}</td>
                                                <td>{travelDirection}</td>
                                                <td>{energy}</td>
                                                <td>{moonPhase}</td>
                                                <td>{moonSigns}</td>
                                                <td>{marathiMonth}</td>
                                                <td>{englishMonth}</td>
                                                <td>{nakshtra}</td>
                                                <td>{day}</td>
                                                <td>{moment(date).format("DD-MM-YYYY")}</td>
                                                <td>
                                                    <img src={Edit} alt="Edit" className="icon me-3"
                                                        onClick={() =>
                                                            navigate("/dashboard/editpanchang", {
                                                                state: {
                                                                    panchang: itm,
                                                                },
                                                            })
                                                        }
                                                    /></td>

                                            </tr>
                                        )
                                    })
                                        : <p className='noDataFound'>No Data Found</p>
                            }
                        </table>
                    </div>
                </div>

                <PaginationSequence
                    data={data?.count}
                    pageNo={pageNo}
                    noOfPages={noOfPages}
                    handlePageChange={handlePageChange}
                    handleDocsPerPage={handleDocsPerPage}
                    docPerPage={docPerPage}
                />
            </Container>
        </div >
    )
}
export default Panchang