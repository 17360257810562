import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "../../Common/Skeleton";
import { useNavigate } from "react-router-dom";
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from "../../Auth/LoginSlice";
import { setActiveKey} from "../../Common/ActiveKeySlice";
import { setActivePageNo } from "../../Common/PaginationSlice";
import PublishModel from "../../Common/Model/PublishModel";
import UnPublishModel from "../../Common/Model/UnPublishModel";
import useDebouncedApiCall from "../../Common/Reuse/Debounce";
import DeleteModel from "../../Common/Model/DeleteModel";
import PaginationSequence from "../../Common/Pagination/PaginationSequence";
import moment from "moment";
import { oldnewOption, timeOptions } from "../../../Utils/GlobalConfigs";
import Select from 'react-select'

const ArticleList = () => {
  const navigate = useNavigate();

  const userState = useSelector((state) => state?.cmsLogin?.userData.data);
  const activePageNumber = useSelector(state => state?.pagination.activepageNo);
  const dispatch = useDispatch();

  //List variables
  const [articleList, setArticleList] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [searchField, setSearchField] = useState("");

  // Delete Article variables
  const [isdelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishArticleId, setPublishArticleId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");

  // for filtering old and new
  const [oldnew, setOldNew] = useState('')
  const [timePeriod, setTimePeriod] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // pagination
  const [pageNo, setPageNo] = useState(1);
  // const [activePageNos, setActivePageNos] = useState(activePageNo?activePageNo:1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  // Function to handle opening the publish modal
  const openPublishModal = (articleId) => {
    setPublishArticleId(articleId);
    setIsPublishModalOpen(true);
  };

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };
  // Function to handle opening the unpublish modal
  const openUnpublishModal = (articleId) => {
    setUnpublishId(articleId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };

  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    dispatch(setActivePageNo(pageNumber));
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }


  // for time period calculation
  const handleTimePeriodChange = (option) => {
    setTimePeriod(option);
    const currentDate = moment();
    let fromDate;

    if (option.value === "oneweek") {
      fromDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    } else if (option.value === "onemonth") {
      fromDate = moment().subtract(1, 'months').format("YYYY-MM-DD");
    } else if (option.value === "oneyear") {
      fromDate = moment().subtract(1, 'years').format("YYYY-MM-DD");
    }

    setStartDate(fromDate);
    setEndDate(currentDate.format("YYYY-MM-DD"));
  };


  useEffect(() => {
    getArticleList()
  }, [docPerPage, pageNo])

  // get article data
  const getArticleList = async () => {
    setListLoader(true);
    
    await fetch(process.env.REACT_APP_BASE_URL + "/priority/titleFilter", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        documentsPerPage: docPerPage,
        page: activePageNumber?activePageNumber:pageNo,
        type: "Article",
        title: searchField ? searchField : "",
        sort: oldnew ? oldnew?.value : "",
        fromDate: startDate ? startDate : "",
        toDate: endDate ? endDate : "",
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Authorization failed / Forbidden") {
          setListLoader(true);
          dispatch(setLogout(null));
          dispatch(setActiveKey(null));
          localStorage.removeItem('persist:root');
          navigate('/');
        } else if (response.status === 200) {
          setArticleList(response);
          setNoOfPages(response?.noOfPages);
        } else {
          setArticleList([]);
        }
        setListLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setListLoader(false);
      });
  };

  // for custome hook call
  const dependencies = [
    searchField ? searchField : "",
    oldnew ? oldnew?.value : "",
    startDate ? startDate : "",
    endDate ? endDate : "",
  ];
  useDebouncedApiCall(getArticleList, dependencies, setListLoader);

  // delete API
  const deleteArticle = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/article/deleteArticle", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        articleId: deleteId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Data deleted succussfully") {
          toast.success("Article Deleted Successfully !")
          setIsDelete(false);
          getArticleList();
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };

  // for toggle
  const handlePublishAction = async (articleId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);

      const result = await fetch(process.env.REACT_APP_BASE_URL + "/article/publishArticle", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          articleId: articleId,
          isPublish: isPublish,
        }),
      });

      const response = await result.json();
      if (response) {
        getArticleList();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishArticleId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };


  return (
    <div className="outletPadding">
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="article"
        loader={listLoader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="article"
        loader={listLoader}
      />
      <DeleteModel
        show={isdelete}
        onHide={() => setIsDelete(false)}
        onDelete={deleteArticle}
        loader={listLoader}
        label="Article"
      />
      <Container>
        {/* Add Article and Filter menu */}
        <Row className="justify-content-between">
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }

              }}
            ></input>
          </Col>

          <Col
            md={2}
            className="d-flex justify-content-end my-auto mt-3 mt-md-0"
          >
            <Button className="primaryBtn" onClick={() => navigate("/dashboard/add-article")}>
              <Icon.PlusCircle className="me-2" size={15} />
              Add Article
            </Button>
          </Col>
        </Row>

        {/* Add Article and Filter menu */}
        <Row className="justify-content-end mt-4">
          <Col md={6} className="d-flex justify-content-end align-items-center">
            <Col md={6} style={{ zIndex: 10000 }} className="me-4">
              <Select
                placeholder="Sort By"
                value={oldnew}
                options={oldnewOption}
                onChange={(option) => setOldNew(option)}
              />
            </Col>
            <Col md={6} style={{ zIndex: 10000 }} >
              <Select
                placeholder="Filter by time period"
                value={timePeriod}
                options={timeOptions}
                onChange={handleTimePeriodChange}
              />
            </Col>
          </Col>
        </Row>

        {/* Article List Table */}
        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Title</th>
                <th>Tags</th>
                <th>Feed Category</th>
                <th>Publish</th>
                <th>Publish Date</th>
                <th>Action</th>
              </thead>
              {listLoader ? (
                <Skeleton rows={10} cols={7} />
              ) : articleList?.data !== undefined && articleList?.data !== null && articleList?.data?.length > 0 ? (
                articleList?.data?.map((itm, index) => {
                  return (
                    <tr key={Math.random() * 999999999}>
                      <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                      <td style={{ width: '250px' }}>{itm?.titleLang?.en}</td>
                      <td style={{ width: '230px' }}>{itm?.tags?.map((row) => (row)).join(', ')}</td>
                      <td style={{ width: '200px' }}>{itm?.contentCategory}</td>
                      <td style={{ width: '120px' }}>
                        {itm?.isPublish ? (
                          <div
                            style={{
                              backgroundColor: "#8FBC8F",
                              borderRadius: "8px",
                              padding: "4px 8px",
                              color: "white",
                              textAlign: "center",
                              cursor: "pointer"
                            }}
                            onClick={() => openUnpublishModal(itm.articleId)}
                          >
                            Published
                          </div>
                        ) : (
                          <div
                            className="switch"
                            onClick={() => openPublishModal(itm.articleId)}
                          >
                            <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                            <span className="slider round" onClick={() => openPublishModal(itm?.articleId)}></span>
                          </div>
                        )}

                      </td>
                      <td>{itm?.toPublishDate ? moment(itm?.toPublishDate).format('DD-MM-YY') : moment(itm?.updatedAt).format('DD-MM-YY')}</td>
                      <td>
                        <img src={View} alt="View" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/view-article", {
                              state: {
                                article: itm,
                              },
                            })
                          } />

                        <img src={Edit} alt="Edit" className="icon me-3"
                          onClick={() =>
                            navigate("/dashboard/edit-article", {
                              state: {
                                article: itm,
                              },
                            })
                          }
                        />

                        <img src={Delete} alt="Delete" className='icon'
                          onClick={() => {
                            setIsDelete(true);
                            setDeleteId(itm?.articleId);
                          }} />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>
        </div>
        <PaginationSequence
          data={articleList?.count}
          pageNo={activePageNumber?activePageNumber:pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />

      </Container >
    </div >
  );
};

export default ArticleList;
