import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';
import SFGFullLogo from '../../Assets/LogoFull.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout, setUserData } from '../Auth/LoginSlice';
import { setActiveKey } from '../Common/ActiveKeySlice';

const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mobile, setMobile] = useState('');
  const [pass, setPass] = useState('');

  const [loader, setLoader] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const validate = () => {
    let FormValid = true;
    if (!mobile || mobile.length < 10) {
      FormValid = false;
      toast.error("Please enter valid mobile No");
    }
    else if (!pass) {
      FormValid = false;
      toast.error("Please enter valid password");
    }
    return FormValid;
  }

  // for login data
  const sendAdmin = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validate()) {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/user/login', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ mobile: mobile, password: pass })
      })
      try {
        const result = await response.json();
        if (result?.message === "Password incorrect") {
          toast.error("Incorrect password ");
          setLoader(false);
          dispatch(setLogout(null));
        } else if (result?.message === "No user found") {
          toast.error("User not Found")
          setLoader(false);
          dispatch(setLogout(null));
        }
        else if (result.data.user.userType === "admin" || result.data.user.userType === "superadmin") {
          toast.success("Login Successfully")
          dispatch(setActiveKey({ activeKey: 1, subActiveKey: 1, activeTab: 'Dashboard' }));
          dispatch(setUserData(result))
          setLoader(false);
          navigate('/dashboard')
        }
        else {
          setLoader(false);
          toast.error('Sorry, Invalid Credentails');
          dispatch(setLogout(null));
        }


      } catch (error) {
        return error;
      }
    } else {
      setLoader(false);
    }
  }


  return (
    <>
      <ToastContainer />
      <div className='login'>
        <Container fluid className='h-100'>
          <Row className='h-100 d-flex justify-content-center align-items-center'>
            <img src={SFGFullLogo} alt="SFG Logo" className='logo' />
            <Col md={4} className='mt-0 mt-md-4'>
              <Card>

                <h2 className='mb-1 mt-1 noselect'>Sri Family Guide CMS</h2>
                <h6 className='mb-2 noselect'>
                  <Icon.BoxArrowInRight className="me-2" />Login to your dashboard
                </h6>

                <Form className="px-4">
                  <FloatingLabel controlId="floatingInput" label="Mobile" className="mb-1">
                    <Form.Control placeholder="Mobile" className='username' name='contactNo' value={mobile} onChange={(e) => {
                      if (
                        !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&
                        e.target.value.length <= 10
                      ) { setMobile(e.target.value); }
                    }} />
                  </FloatingLabel>

                  <InputGroup data-aos="fade-up" data-aos-delay="200" className="mb-4 noselect">
                    <FloatingLabel controlId="floatingInput" label="Password" className='d-flex'>
                      <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" name='password' value={pass} onChange={(e) => setPass(e.target.value)} />
                      <InputGroup.Text className='user'>{showPassword ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setshowPassword(!showPassword)} /> : <Icon.Eye size={20} style={{ cursor: 'pointer' }} onClick={() => setshowPassword(!showPassword)} />}</InputGroup.Text>
                    </FloatingLabel>
                  </InputGroup>

                  {loader ? <Spinner animation="border" variant="primary" /> :
                    <Button className="primaryBtn mx-auto" type="submit"
                      onClick={(e) => sendAdmin(e)} data-aos="fade-up" data-aos-delay="300" >
                      <Icon.BoxArrowRight className='me-2' />Login
                    </Button>
                  }

                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>

  )
}

export default Login;