import React, { useEffect, useState } from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useSpeechSynthesis } from "react-speech-kit";
import TextToSpeech from "../../Common/Reuse/TextToSpeech/TextToSpeech";


const ViewVideo = () => {
    const location = useLocation();
    const contentData = location.state.contentData;
    const navigate = useNavigate()

    const [isSpeaking, setIsSpeaking] = useState(false);
    const { speak, voices, cancel } = useSpeechSynthesis();

    useEffect(() => {
        return () => {
            cancel();
        };
    }, []);

    return (
        <div className="outletPadding">
            <Container>
                <Button className="backBtn" onClick={() => navigate(-1)}>
                    <Icon.ArrowLeft className="me-2" />Back
                </Button>
                <Row className="mb-2 mt-4">
                    <h4 className="mb-0 text-center">
                        <Icon.Person className="me-1" /> Video Details
                    </h4>
                </Row>
                <Row className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <tbody>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Content Category
                                    </th>
                                    <td>{contentData?.contentCategory ? contentData?.contentCategory : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Tag Category
                                    </th>
                                    <td>{contentData?.tagsCategory ? contentData?.tagsCategory : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        ContentType
                                    </th>
                                    <td>{contentData?.contentType ? contentData?.contentType : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Mood
                                    </th>
                                    <td className="text-capitalize">{contentData?.moods ? contentData?.moods?.join(", ") : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Page
                                    </th>
                                    <td>{contentData?.pageName ? contentData?.pageName : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Title in English
                                    </th>
                                    <td>{contentData?.titleLang?.en ? contentData?.titleLang?.en : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Title in Marathii
                                    </th>
                                    <td>{contentData?.titleLang?.mr ? contentData?.titleLang?.mr : "-"}</td>
                                </tr>


                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Content Image Url
                                    </th>
                                    <td>{contentData?.imgURL ? contentData?.imgURL : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Content Video Link
                                    </th>
                                    <td>{contentData?.videoURL ? contentData?.videoURL : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Content Gif Link
                                    </th>
                                    <td>{contentData?.gifURL ? contentData?.gifURL : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Tags
                                    </th>
                                    <td>{contentData?.tags.join(", ") ? contentData?.tags.join(", ") : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Created By
                                    </th>
                                    <td>{contentData?.createdById ? contentData?.createdById : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Short Description in English
                                    </th>
                                    <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                                        {contentData?.shortDescriptionLang?.en ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: contentData?.shortDescriptionLang?.en,
                                                }}
                                            ></div>
                                        ) : (
                                            "-"
                                        )}

                                    </td>
                                </tr>


                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Short Description in Marathi
                                    </th>
                                    <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                                        {contentData?.shortDescriptionLang?.mr ? (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: contentData?.shortDescriptionLang?.mr,
                                                }}
                                            ></div>
                                        ) : (
                                            "-"
                                        )}

                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Full Description in English
                                    </th>
                                    <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                                        {contentData?.descriptionLang?.en ? (
                                            <>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: contentData?.descriptionLang?.en,
                                                    }}
                                                ></div>
                                                <TextToSpeech htmlContent={contentData?.descriptionLang?.en} language="mr" />
                                            </>

                                        ) : (
                                            "-"
                                        )}


                                    </td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Full Description in Marathi
                                    </th>
                                    <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                                        {contentData?.descriptionLang?.mr ? (
                                            <>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: contentData?.descriptionLang?.mr,
                                                    }}
                                                ></div>
                                                <TextToSpeech htmlContent={contentData?.descriptionLang?.mr} language="mr" />
                                            </>
                                        ) : (
                                            "-"
                                        )}


                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </Row >
            </Container >
        </div >
    );
};

export default ViewVideo;
